import { signIn } from "next-auth/react"
import { useEffect } from "react"
import { pages } from "@/urls"
import { Container } from "~/components/container"
import { LoadingOverlay } from "~/components/loading-overlay"
import { Layout } from "~/containers/layout"
import { useRedirect } from "~/hooks/use-redirect"
import { useRouter } from "~/hooks/use-router"

export function Login() {
  const { redirectTo } = useRedirect()
  const {
    query: {
      loggedOut: _loggedOut,
      connection,
      context: marketingContext,
      unblocked,
    },
  } = useRouter()

  const CLEVER_CONNECTION = "Clever"
  const isCleverLogin = connection == CLEVER_CONNECTION

  useEffect(() => {
    const authParams = {
      "ext-loggedOut": !!_loggedOut,
      "ext-purchasing": !!redirectTo?.includes("purchase"),
      "ext-unblocked": unblocked || "false",
      "ext-marketingContext": marketingContext || "false",
      "prompt": "login",
    }

    if (isCleverLogin) {
      authParams.connection = CLEVER_CONNECTION
    }

    signIn(
      "auth0",
      {
        callbackUrl: `${pages.user.social}${
          redirectTo ? `?returnTo=${redirectTo}` : ""
        }`,
      },
      authParams,
    )
  }, [])

  return (
    <Layout>
      <Container>
        <LoadingOverlay show={true} showLabel={false} />
      </Container>
    </Layout>
  )
}
