/* eslint-disable @typescript-eslint/no-explicit-any */
import { styled } from "@/styles/stitches.config"
import { AnyComponent } from "~/components/any"

export const Flex = styled(AnyComponent, {
  boxSizing: "border-box",
  display: "flex",
  position: "relative",

  variants: {
    direction: {
      row: {
        flexDirection: "row",
      },
      column: {
        flexDirection: "column",
      },
      rowReverse: {
        flexDirection: "row-reverse",
      },
      columnReverse: {
        flexDirection: "column-reverse",
      },
    },
    align: {
      start: {
        alignItems: "flex-start",
      },
      center: {
        alignItems: "center",
      },
      end: {
        alignItems: "flex-end",
      },
      stretch: {
        alignItems: "stretch",
      },
      baseline: {
        alignItems: "baseline",
      },
    },
    justify: {
      start: {
        justifyContent: "flex-start",
      },
      center: {
        justifyContent: "center",
      },
      end: {
        justifyContent: "flex-end",
      },
      between: {
        justifyContent: "space-between",
      },
      around: {
        justifyContent: "space-around",
      },
    },
    wrap: {
      noWrap: {
        flexWrap: "nowrap",
      },
      wrap: {
        flexWrap: "wrap",
      },
      wrapReverse: {
        flexWrap: "wrap-reverse",
      },
    },
    gap: {
      0: { gap: "$0" },
      1: { gap: "$1" },
      2: { gap: "$2" },
      3: { gap: "$3" },
      4: { gap: "$4" },
      5: { gap: "$5" },
      6: { gap: "$6" },
      7: { gap: "$7" },
      8: { gap: "$8" },
      9: { gap: "$9" },
      10: { gap: "$10" },
      11: { gap: "$11" },
    },
    wide: {
      true: {
        width: "100%",
      },
    },
    tall: {
      true: {
        height: "100%",
      },
    },
  },

  defaultVariants: {
    direction: "row",
    align: "center",
    justify: "start",
    wrap: "noWrap",
    gap: "3",
  },
})
