import React, { ReactElement, ReactNode, Children } from "react"

export type AnyElement<C extends React.ElementType> = {
  as?: C
  children?: React.ReactNode
} & React.ComponentPropsWithoutRef<C>

/**
 * <AnyComponent as="span" /> will render as a <span/> element on the DOM.
 */
export const AnyComponent = React.forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  <C extends React.ElementType>(
    { as, ...props }: AnyElement<C>,
    ref: any,
  ): React.ReactElement => {
    const Component = as || "div"
    const refProps = {
      [typeof Component === "string" ? "ref" : "innerRef"]: ref,
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return <Component {...refProps} {...props} />
  },
)
AnyComponent.displayName = "BaseComponent"

export function AnyHeader({
  as = "h2",
  ...props
}: PropsOf<typeof AnyComponent> & {
  as?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6"
}): ReactElement {
  return <AnyComponent {...props} as={as} />
}

export const withChildren = <C extends ReactElement<{ children: ReactNode }>>(
  Component: C,
  children: {
    before?: ReactNode
    after?: ReactNode
  },
): C =>
  React.cloneElement(Component, {
    children: Children.toArray(children.before)
      .concat(Children.toArray(Component.props.children))
      .concat(Children.toArray(children.after)),
  }) as unknown as C
