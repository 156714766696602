import PropTypes from "prop-types"
import { styled, keyframes } from "@/styles/stitches.config"
import { Flex } from "~/components/flex"
import { ReactComponent as LaptopSvg } from "~/components/loading-overlay/aoeu-loader-computer.svg"

const Overlay = styled("div", {
  alignItems: "center",
  background: "rgba(235, 240, 243, 0.8)", // athensGray @ 80%
  display: "flex",
  flexDirection: "column",
  height: "100%",
  justifyContent: "center",
  overflow: "hidden scroll",
  position: "fixed",
  top: 0,
  width: "100%",
  zIndex: 10,
})

const Loader = styled("div", {
  position: "relative",
})

const Laptop = styled(LaptopSvg, {
  height: "13.75rem",
  width: "20.625rem",
})

// ಠ_ಠ ``

const animatedDotsColor = (alpha = 0) => `rgba(7, 116, 166, ${alpha})`
const animatedDotsSize = "0.5rem"

const dotPulse = keyframes({
  ["0%"]: {
    backgroundColor: animatedDotsColor(),
    transform: "scale(1)",
  },
  ["25%"]: {
    backgroundColor: animatedDotsColor(0.5),
    transform: "scale(2.25)",
  },
  ["50%"]: {
    backgroundColor: animatedDotsColor(1),
    transform: "scale(3.5)",
  },
  ["75%"]: {
    backgroundColor: animatedDotsColor(0.5),
    transform: "scale(2.25)",
  },
  ["100%"]: {
    backgroundColor: animatedDotsColor(),
    transform: "scale(1)",
  },
})

const Dot = styled("div", {
  animation: `${dotPulse} 1.5s infinite linear`,
  backgroundColor: animatedDotsColor(),
  borderRadius: `calc(${animatedDotsSize} / 2)`,
  height: animatedDotsSize,
  width: animatedDotsSize,
})

const Dots = () => {
  const dotsStyle = {
    left: "7.25rem",
    position: "absolute",
    top: "6.15rem",
    width: "8rem",
  }

  return (
    <Flex align="stretch" justify="between" css={dotsStyle}>
      <Dot css={{ animationDelay: "0s" }} />
      <Dot css={{ animationDelay: "0.25s" }} />
      <Dot css={{ animationDelay: "0.5s" }} />
    </Flex>
  )
}

const Label = styled("div", {
  color: "$darkGray",
  fontSize: "1.25rem",
  fontWeight: 700,
  height: "1.6875rem",
  lineHeight: "1.6875rem",
  smoothTransition: "all",
})

const OverlayContainer = styled("div", {
  width: "100vw",
  height: "100vh",
  position: "fixed",
  left: 0,
  top: 0,
})

export function LoadingOverlay({ show, showLabel, label, ...props }) {
  return show ? (
    <OverlayContainer>
      <Overlay data-testid={props["data-testid"] ?? "loading-overlay"}>
        <Loader>
          <Laptop />
          <Dots />
        </Loader>
        {showLabel ? <Label>{label}</Label> : null}
      </Overlay>
    </OverlayContainer>
  ) : null
}

LoadingOverlay.propTypes = {
  "show": PropTypes.bool.isRequired,
  "showLabel": PropTypes.bool.isRequired,
  "label": PropTypes.string,
  "data-testid": PropTypes.string,
}

LoadingOverlay.defaultProps = {
  show: false,
  showLabel: true,
}
